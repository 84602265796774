<template>
  <div class="InstrumentManage content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="名称">
              <el-input
                placeholder="输入名称搜索"
                clearable
                v-model="Name"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                placeholder="请选择"
                v-model="Active"
                @change="handleSearch"
              >
                <el-option
                  :label="item.Name"
                  :value="item.Value"
                  v-for="item in statusList"
                  :key="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button type="primary" size="small" @click="addManage"
            >新增仪器</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @select="select"
      >
        <el-table-column label="仪器编号" prop="InstrumentID"></el-table-column>
        <el-table-column label="仪器名称" prop="Name"> </el-table-column>
        <el-table-column label="品牌编号" prop="BrandID"></el-table-column>
        <el-table-column label="品牌名称" prop="BrandName"></el-table-column>
        <el-table-column label="是否仪器" prop="isInstrument">
          <template slot-scope="scope">{{
            scope.row.isInstrument ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="Remark"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="有效性" prop="Active">
          <template slot-scope="scope">{{
            scope.row.Active ? "有效" : "无效"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >编辑</el-button>
            <!-- <el-button type="primary" @click="copy(scope.row)" size="small"
              >复制</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 dis_flex flex_x_between">
        <template>
          <div>
            <!-- <el-checkbox v-model="allChecked" class="marrt_20" @change="toggleSelection">全选</el-checkbox>
            <el-dropdown placement="top" trigger="click" @command="handleCommand">
              <el-button type="primary" size="small">批量设置</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="状态">状态</el-dropdown-item>
                <el-dropdown-item command="线上分类">线上分类</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
        </template>

        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!--新增、编辑弹出框-->
    <el-dialog
      :title="dialogtype == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      destroy-on-close
      append-to-body
      :before-close="closeDialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="Incomerules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="仪器名称" prop="Name">
                <el-input
                  clearable
                  v-model="ruleForm.Name"
                  placeholder="请输入仪器名称"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="品牌编号" prop="BrandID">
                 <el-select v-model="ruleForm.BrandID" placeholder="请选择" class="width_220">
                  <el-option
                    v-for="item in BrandIDlist"
                    :key="item.BrandID"
                    :label="item.Name"
                    :value="item.BrandID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="Remark">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请输入备注"
                  v-model="ruleForm.Remark"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否仪器" prop="isInstrument">
                <el-radio v-model="ruleForm.isInstrument" :label="true"
                  >是</el-radio
                >
                <el-radio v-model="ruleForm.isInstrument" :label="false"
                  >否</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="dialogtype == 2">
            <el-col :span="12">
              <el-form-item label="有效性" prop="Active">
                <el-radio v-model="ruleForm.Active" :label="true"
                  >有效</el-radio
                >
                <el-radio v-model="ruleForm.Active" :label="false"
                  >无效</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../../api/CRM/Instrument/InstrumentManage";
import permission from "@/components/js/permission.js";
export default {
  name: "InstrumentManage",
  /**  引入的组件  */
  components: {},
  beforeCreate(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "仪器管理");
    });
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      BrandIDlist:[], //品牌编号
      InstrumentID: "",
      Name: "",
      EntityID: "",
      Active: null,
      dialogtype: 1, // 1新增 2编辑
      dialogVisible: false, // 弹窗
      statusList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      tableData: [],
      ruleForm: {
        isInstrument: true,
      },
      Incomerules: {
        offlineName: [
          { required: true, message: "请输入线下名称", trigger: "blur" },
        ],
        isInstrument: [{ required: true, trigger: "blur" }],
        onlineName: [
          { required: true, message: "请填写线上别名", trigger: "blur" },
        ],
        onlineType: [
          { required: true, message: "请选择线上分类", trigger: "change" },
        ],
        offlineClass: [
          { required: true, message: "请选择线下类型", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      paginations: {
        page: 1,
        total: 20,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      // 选中数组
      multipleSelection: [],
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    //搜索
    handleSearch() {
      var that = this;
      const { Active, EntityID, Name, InstrumentID } = this;
      var params = {
        Active,
        EntityID,
        Name,
        InstrumentID,
        PageNum: this.paginations.page,
      };
      API.skinInstrument(params).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        }
      });
    },
    //复制
    copy(row) {},
    // 编辑
    editor(row) {
      const { InstrumentID, Name, BrandID, isInstrument, Remark, Active } = row;
      this.ruleForm = {
        InstrumentID,
        Name,
        BrandID,
        isInstrument,
        Remark,
        Active,
      };
      this.dialogtype = 2;
      this.dialogVisible = true;
    },
    // 翻页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
    // 批量选择
    handleCommand(command) {
      console.log(command);
    },
    // 修改选中状态
    toggleSelection(value) {
      var that = this;
      if (value) {
        that.multipleSelection = Object.assign([], that.tableData);
        that.tableData.forEach((row) => {
          that.$refs.multipleTable.toggleRowSelection(row, true);
        });
      } else {
        that.$refs.multipleTable.clearSelection();
        that.multipleSelection = [];
      }
    },
    // 选中项变化
    select(rows) {
      var that = this;
      that.multipleSelection = Object.assign([], rows);
      that.allChecked = rows.length == that.tableData.length;
    },
    addManage() {
      this.getBrandIDlist();
      this.dialogtype = 1;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.ruleForm = {
        isInstrument: true,
      };
      this.dialogVisible = false;
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createManage();
          } else {
            that.updateManage();
          }
        }
      });
    },
    // 新增
    createManage() {
      var that = this;
      API.create(this.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    // 编辑
    updateManage() {
      var that = this;
      API.update(this.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已修改!");
          that.closeDialog();
          that.handleSearch();
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    getBrandIDlist(){
      var params = {
        Name:"",
        Active:null
      }
      API.BrandIDlist(params).then(res=>{
        if (res.StateCode == 200) {
          this.BrandIDlist = res.Data;
        }else{
          this.$message.error(res.Message)
        }
      })
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "仪器管理"
    );
    this.getBrandIDlist();
    this.handleSearch();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.marrt_20 {
  margin-right: 20px !important;
}
</style>
