import * as API from '@/api/index'

export default {
    //总分配置列表
    skinInstrument:params =>{
        return API.POST('api/skinInstrument/all',params)
    },
    //8.2.创建总分配置
    create:params =>{
        return API.POST('api/skinInstrument/create',params)
    },
    // 修改总分配置
    update:params =>{
        return API.POST('api/skinInstrument/update',params)
    },
    BrandIDlist:params=>{
        return API.POST("api/skinBrand/list",params)
    }

}